<template>
  <div class="container">
    <div class="search">
      <el-input
        placeholder="请输入单位全称或统一社会信用代码查询获证结果"
        v-model="searchTxt"
      ></el-input>
      <span @click="searchResult">查询</span>
    </div>
    <div id="content">
        <div class="common-style">
            <h3>获证查询</h3>
            <ul>
                <li v-for="(item,index) in queryList" :key="index" @click="certification(item)">
                  <img :src="item.src" alt="">
                  <span>{{item.name}}</span>
                </li>
            </ul>
        </div>
        <div class="common-style">
            <h3>从业机构</h3>
            <ul>
                <li v-for="(item,index) in institutionsList" :key="index" @click="employed(item)">
                  <img :src="item.src" alt="">
                  <span>{{item.name}}</span>
                </li>
            </ul>
        </div>
        <div class="common-style">
            <h3>检验标准</h3>
            <ul>
              <li v-for="(item,index) in inspectionList" :key="index" @click="inspection(item)">
                <img :src="item.src" alt="">
                <span>{{item.name}}</span>
              </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTxt: "",
      queryList: [
        {
          src: require('../assets/qualification-certification.png'),
          name: '资质认证',
          id: 1
        },
        {
          src: require('../assets/credit-certification.png'),
          name: '信用认证',
          id:2
        },
        {
          src: require('../assets/manage-certification.png'),
          name: '管理体系认证',
          id:3
        },
        {
          src: require('../assets/service-certification.png'),
          name: '服务认证',
          id:4
        },
        {
          src: require('../assets/mandatory-certification.png'),
          name: '强制性产品认证',
          id:5
        },
        {
          src: require('../assets/voluntary-certification.png'),
          name: '自愿性产品认证',
          id:6
        },
        {
          src: require('../assets/food-certification.png'),
          name: '食品农产品认证',
          id:7
        }
      ],
      institutionsList: [
        {
          src: require('../assets/qualification-institutions.png'),
          name: '国家级资质认定机构',
          id:7
        },
        {
          src: require('../assets/province-institutions.png'),
          name: '省级资质认定机构',
          id:6
        },
        {
          src: require('../assets/certification-institutions.png'),
          name: '认证机构',
          id:5
        },
        {
          src: require('../assets/mandatory-institutions.png'),
          name: '强制性产品认证机构',
          id:4
        },
        {
          src: require('../assets/laboratory.png'),
          name: '强制性产品认证实验室',
          id:3
        },
        {
          src: require('../assets/quality-Inspection.png'),
          name: '国家质检中心',
          id:2
        },
        {
          src: require('../assets/food-re-inspection.png'),
          name: '食品复检机构',
          id:1
        }
      ],
      inspectionList: [
        {
          src: require('../assets/countries-standard.png'),
          name: '认证认可国家标准',
          id:1
        },
        {
          src: require('../assets/industry-standard.png'),
          name: '认证认可行业标准',
          id:2
        }
      ]
    };
  },
  methods: {
    searchResult() {
      this.$router.push('/result');
      localStorage.setItem('search',this.searchTxt)
      localStorage.setItem('id',1)
      // this.$router.push({
      //   path: "/result",
      //   query: { search: this.searchTxt,id:1 },
      // });
    },
    certification(val){
      localStorage.setItem('id',val.id)
      this.$router.push('/result');
    },
    employed(val){
      localStorage.setItem('organId',val.id)
      this.$router.push('/professionalInstitutions');
      // this.$router.push({
      //   path: "/professionalInstitutions",
      //   query: { id: val.id }
      // });
    },
    inspection(val){
      localStorage.setItem('examineId',val.id)
      this.$router.push('/inspectionStandard');
      // this.$router.push({
      //   path: "/inspectionStandard",
      //   query: { id: val.id }
      // });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  min-width: 1200px;
  margin: 0 auto;
  .search {
    position: relative;
    width: 826px;
    margin: 32px auto 40px;
    height: 48px;
    line-height: 48px;
    span {
      display: inline-block;
      color: #fff;
      height: 48px;
      line-height: 48px;
      width: 105px;
      background-color: #4882F8;
      position: absolute;
      right: -2px;
      bottom: 0;
      letter-spacing: 6px;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
    }
  }
  .el-input {
    width: 100%;
    text-align: left;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
  }

  /deep/.el-input__inner {
    line-height: 48px;
    height: 48px;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #333;
  }
  #content {
    width: 1152px;
    background-color: #fff;
    padding:0px 24px 40px 24px;
    margin:0px auto 95px auto;
  }
  #content .common-style>h3{
      font-size: 24px;
      font-weight: 500;
      color: #323233;
      padding:24px 0px 12px 0px;
      margin:0;
  }
  #content .common-style>ul{
      padding:48px 84px 0px 84px;
      border-top:1px solid #DCDEE0;
      overflow: hidden;
      margin:0;
  }
  #content .common-style>ul>li{
      float:left;
      width: 228px;
      height: 142px;
      margin:0px 24px 24px 0px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
      opacity: 1;
      cursor: pointer;
  }
  #content .common-style>ul>li:hover{
      background: #F2F3F5;
  }
  #content .common-style>ul>li:nth-child(4n){
      margin-right:0px;
  }
  #content .common-style>ul>li>img{
      display: block;
      width: 90px;
      height: 90px;
      background-size:100% 100%;
      margin:10px auto 0 auto;
  }
}
</style>
